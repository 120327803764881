
import { getJwt } from "#app/(unauthorized)/authentication/jwtStorageService";
import { StoredItem } from "#app/lib/cache/localCache";
import { TimeSpan } from "#app/lib/TimeSpan";
import { getText } from "@/lib/fetchClient";
import { queryOptions } from "@tanstack/react-query";

const GATEWAY_PATH = "authentication/v1";


export async function getSaml() : Promise<string> {
    const jwt = getJwt();

    const result = await getText(`/${GATEWAY_PATH}/Saml`, {
      headers: { JWT: jwt || "" },
    });

    return result;
}


export function useSamlQueryOptions() {
    return queryOptions({
        queryKey: [StoredItem.auth_saml],
        queryFn: () => getSaml(),
        staleTime: TimeSpan.fromMinutes(4).totalMilliseconds
    });
  }


